import {
  Box,
  Checkbox,
  Form,
  FormField,
  FormLabel,
  Grid,
  GridItem,
  Input,
  MultiSelectWithSearch,
  Radio,
  Toggle
} from '@plusplusminus/plusplusdash'
import { TagTypes } from 'common/enums'
import { Tag } from 'common/types'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import Select from 'components/Select'
import Button from 'components/Button'
import { Upload } from 'components/Upload'
import {
  BrandContactFields,
  BrandQuery,
  BrandsInput,
  Image,
  Maybe,
  useProductSortQuery,
  useUpdateBrandMutation
} from 'generated'
import { useSetImageOnBrandMutation, useSetImagesOnBrandMutation } from 'hooks/brands'
import { useAllTags } from 'hooks/tags'
import { isEqual } from 'lodash'
import { useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { getDirtyFieldValues } from 'utils'
import { buildTagSelectOptions } from 'utils/tags'
import { ImageContext, Images } from './BrandImages'
import { useAuthToken } from 'hooks/useAuthToken'

interface BrandEditProps {
  brandId: string
  brand: NonNullable<BrandQuery['brand']>
  cancel: () => void
  updateCallback: () => void
}

const apiUrl = process.env.REACT_APP_TLE_API_REST || ''

export const BrandEdit: React.FC<BrandEditProps> = (props) => {
  const { brand, brandId, cancel, updateCallback } = props
  const [authToken] = useAuthToken()

  const { data: productSort } = useProductSortQuery()

  const [brandTags, setBrandTags] = useState(brand.tags?.map(({ id }) => id))
  const [images, setImages] = useState(brand.images)

  /* Images */
  const [featuredPortrait, setFeaturedPortrait] = useState<Maybe<Image> | undefined>(brand.featuredPortrait)
  const [featuredLandscape, setFeaturedLandscape] = useState<Maybe<Image> | undefined>(brand.featuredLandscape)
  const [featuredEditMode, setFeaturedEditMode] = useState<'portrait' | 'landscape'>('portrait')
  const [hiddenFields, setHiddenFields] = useState<Array<BrandContactFields>>(brand.hiddenFields)

  const {
    control,
    register,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm<BrandsInput>({
    defaultValues: {
      name: brand.name,
      description: brand.description,
      enabled: brand.enabled,
      meta: brand.meta
    }
  })
  const [updateBrand, { loading: loadingUpdateBrand }] = useUpdateBrandMutation({
    onCompleted: () => {
      toast.success('Successfully updated brand.')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. please contact admin')
    }
  })

  const [tags] = useAllTags(TagTypes.Brand)
  const [productTags] = useAllTags(TagTypes.Product)

  const [uploading, setUploading] = useState(false)
  const { setImageOnBrand: setFeaturedPortraitOnBrand, loading: loadingPortrait } = useSetImageOnBrandMutation(
    'portrait',
    {
      onCompleted: () => {
        toast.success('Successfully set portrait image.')
        updateCallback()
      },
      onError: (error) => {
        console.log({ error })
        toast.error('Server error. could not set portrait image.')
      }
    }
  )
  const { setImageOnBrand: setFeaturedLandscapeOnBrand, loading: loadingLandscape } = useSetImageOnBrandMutation(
    'landscape',
    {
      onCompleted: () => {
        toast.success('Successfully set landscape image.')
        updateCallback()
      },
      onError: (error) => {
        console.log({ error })
        toast.error('Server error. could not set landscape image.')
      }
    }
  )
  const { setImagesOnBrand, loading: loadingImage } = useSetImagesOnBrandMutation({
    onCompleted: () => {
      toast.success('Successfully set image.')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Server error. could not set image.')
    }
  })

  const loading = loadingUpdateBrand || loadingPortrait || loadingLandscape || loadingImage || uploading

  const onSubmit = (data: BrandsInput) => {
    const submitData = getDirtyFieldValues(data, dirtyFields)
    if (brandTags && !isEqual(brandTags, brand.tags)) {
      submitData.tagIds = brandTags
    }

    submitData.hiddenFields = hiddenFields

    const keys = Object.keys(submitData) as Array<keyof typeof submitData>
    keys.forEach((k) => {
      if (submitData[k] === '') {
        submitData[k] = null
      }
    })

    updateBrand({
      variables: { id: brandId, update: submitData }
    })

    if (featuredPortrait && featuredPortrait.url !== brand.featuredPortrait?.url) {
      setFeaturedPortraitOnBrand(brand.id, featuredPortrait.id)
    }
    if (featuredLandscape && featuredLandscape.url !== brand.featuredLandscape?.url) {
      setFeaturedLandscapeOnBrand(brand.id, featuredLandscape.id)
    }

    if (images && !isEqual(images, brand.images)) {
      setImagesOnBrand(
        brand.id,
        images.map((f) => f.id)
      )
    }
  }

  const onChangeTags = (value: string[]) => {
    setBrandTags(value)
  }
  const onUpload = async (event: any) => {
    const files = event?.target?.files
    setUploading(true)

    try {
      for (const file of files) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(`${apiUrl}/images/upload`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        })

        if (!response.ok) {
          throw new Error('Upload failed')
        }

        const image = await response.json()

        if (image && images && !images.find((f) => f.id === image.id)) {
          setImages((state) => [...(state || []), image])
        }
      }
    } catch (error) {
      console.error(error)
      toast.error('Server error. could not upload image.')
    } finally {
      setUploading(false)
    }
  }

  const onRemoveImage = (url: string) => () => {
    const filterImgs = images?.filter((f) => f.url !== url) || []
    setImages(filterImgs)

    // set featured image to first if removed
    if (featuredPortrait?.url === url) {
      setFeaturedPortrait(null)
    }
    if (featuredLandscape?.url === url) {
      setFeaturedLandscape(null)
    }
  }

  const tagSelectItems = buildTagSelectOptions(tags)
  const productTagSelectItems =
    productTags?.map((tag) => ({
      key: tag.id,
      value: tag.name,
      label: tag.name.charAt(0) + tag.name.slice(1)
    })) || []

  const productSortOptions = useMemo(() => {
    if (productSort?.brandProductSortOptions) {
      return productSort.brandProductSortOptions.map(({ label, value }) => ({
        value,
        label,
        key: value
      }))
    }
  }, [productSort])

  const setFeaturedImage = (img: Image) => {
    if (featuredEditMode === 'portrait') {
      setFeaturedPortrait(img)
    } else if (featuredEditMode === 'landscape') {
      setFeaturedLandscape(img)
    }
  }

  const imageContextValue = useMemo(
    () => ({ setFeaturedImage, featuredLandscape, featuredPortrait, featuredEditMode, onRemoveImage }),
    [setFeaturedImage, featuredLandscape, featuredPortrait, featuredEditMode, onRemoveImage]
  )
  const onChangeHiddenFields = (field: BrandContactFields) => {
    setHiddenFields((hiddenField) => {
      if (hiddenField.includes(field)) {
        return hiddenField.filter((f) => f !== field)
      } else {
        return [...hiddenField, field]
      }
    })
  }

  return (
    <Box className="p-5">
      <Card className="mb-5">
        <Card.Header>Edit Brand Details</Card.Header>
        <Card.Content>
          <div className="flex">
            <Form className="w-full mb-4" onSubmit={handleSubmit(onSubmit)}>
              <Grid columns={2} rowGap={4} columnGap={4} className="mb-8">
                <FormField>
                  <FormLabel htmlFor="name">Brand Name</FormLabel>
                  <Input
                    id="name"
                    name="name"
                    as="input"
                    variant="standard"
                    width="full"
                    defaultValue={brand.name ?? ''}
                    placeholder={brand.providerName ?? ''}
                    ref={register}
                  />
                </FormField>
                <GridItem colStart={1} colSpan={2}>
                  <FormField>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <Input
                      id="description"
                      name="description"
                      as="textarea"
                      variant="standard"
                      width="full"
                      defaultValue={brand.description ?? ''}
                      placeholder={brand.providerDescription ?? ''}
                      ref={register}
                      className="h-32"
                    />
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField>
                    <FormLabel htmlFor="meta.location">Stock Location</FormLabel>
                    <Controller
                      defaultValue={brand.enabled}
                      control={control}
                      name="meta.location"
                      render={({ onChange }) => (
                        <Select
                          options={[
                            {
                              key: '0',
                              value: '',
                              label: 'Select Location'
                            },
                            ...(brand.locations?.map((l) => ({
                              key: l.id?.toString() ?? '',
                              value: l.id?.toString() ?? '',
                              label: l.name ?? ''
                            })) ?? [])
                          ]}
                          value={brand.meta?.location ?? ''}
                          onChange={(value) => onChange(value)}
                        />
                      )}
                    />
                    <FormLabel>ReSync Brand after changing location</FormLabel>
                  </FormField>
                </GridItem>
                <GridItem>
                  <FormField>
                    <FormLabel htmlFor="enabled">
                      Enabled
                      <Controller
                        defaultValue={brand.enabled}
                        control={control}
                        name="enabled"
                        render={({ onChange, value }) => (
                          <Toggle aria-label="enabled" on={value} onClick={() => onChange(!value)} />
                        )}
                      />
                    </FormLabel>
                  </FormField>
                </GridItem>
                <GridItem colStart={2} rowStart={1}>
                  <div className="text-sm text-gray-700">Tags</div>
                  {tagSelectItems.length > 0 ? (
                    <MultiSelectWithSearch
                      items={tagSelectItems}
                      value={brand.tags?.map(({ id }: Tag) => id) || []}
                      onChange={onChangeTags}
                    />
                  ) : null}
                </GridItem>
              </Grid>
              <div className="py-8 border-b border-t border-gray-200">
                <FormLabel className="block mb-4 font-medium">Images</FormLabel>
                <div className="mb-2">
                  <p className="mb-1 text-sm">Which featured image are you editing?</p>
                  <FormLabel htmlFor="portrait" className="flex items-center mb-2">
                    <Radio
                      id="portrait"
                      name="featuredMode"
                      checked={featuredEditMode === 'portrait'}
                      onClick={() => setFeaturedEditMode('portrait')}
                    />
                    Portrait
                  </FormLabel>
                  <FormLabel htmlFor="landscape" className="flex items-center">
                    <Radio
                      id="landscape"
                      name="featuredMode"
                      checked={featuredEditMode === 'landscape'}
                      onClick={() => setFeaturedEditMode('landscape')}
                    />
                    Landscape
                  </FormLabel>
                </div>
                <ImageContext.Provider value={imageContextValue}>
                  <Images images={images} setImages={setImages} />
                </ImageContext.Provider>
                <Upload uploading={uploading} onUpload={onUpload} />
              </div>
              <div className="py-5 border-b border-gray-200">
                <h4 className="text-sm font-medium mb-4">Contact Details</h4>
                <div className="grid grid-cols-2 gap-4">
                  <FormField>
                    <FormLabel>Phone Number</FormLabel>
                    <Input
                      id="phone"
                      name="phone"
                      defaultValue={brand.phone ?? ''}
                      placeholder={brand.providerPhone ?? ''}
                      variant="standard"
                      ref={register}
                      width="full"
                    />
                  </FormField>
                  <FormField>
                    <FormLabel>Email Address</FormLabel>
                    <Input
                      id="email"
                      name="email"
                      defaultValue={brand.email ?? ''}
                      placeholder={brand.providerEmail ?? ''}
                      variant="standard"
                      ref={register}
                      width="full"
                    />
                  </FormField>
                  <div className="flex flex-col w-52">
                    <FormLabel htmlFor="isPhoneNumberHidden" className="flex justify-between">
                      Hide Phone Number
                      <Checkbox
                        id="isPhoneNumberHidden"
                        aria-label="enabled"
                        checked={hiddenFields.includes(BrandContactFields.Phone)}
                        onChange={() => onChangeHiddenFields(BrandContactFields.Phone)}
                      />
                    </FormLabel>
                    <FormLabel htmlFor="isAddressHidden" className="flex justify-between">
                      Hide Address
                      <Checkbox
                        id="isAddressHidden"
                        aria-label="enabled"
                        checked={hiddenFields.includes(BrandContactFields.Address)}
                        onChange={() => onChangeHiddenFields(BrandContactFields.Address)}
                      />
                    </FormLabel>
                    <FormLabel htmlFor="isEmailHidden" className="flex justify-between">
                      Hide Email
                      <Checkbox
                        id="isEmailHidden"
                        aria-label="enabled"
                        checked={hiddenFields.includes(BrandContactFields.Email)}
                        onChange={() => onChangeHiddenFields(BrandContactFields.Email)}
                      />
                    </FormLabel>
                  </div>
                </div>
              </div>
              <div className="py-5 border-b border-gray-200">
                <h4 className="text-sm font-medium mb-4">Website</h4>
                <div className="grid grid-cols-2 gap-4">
                  <FormField>
                    <FormLabel>Website URL</FormLabel>
                    <Input
                      id="websiteOverride"
                      name="websiteOverride"
                      variant="standard"
                      ref={register}
                      defaultValue={brand.websiteOverride ?? ''}
                      placeholder={brand.website ?? ''}
                      width="full"
                    />
                  </FormField>
                  <FormLabel htmlFor="isEmailHidden">
                    Hide Website
                    <Checkbox
                      id="isWebsiteHidden"
                      className="block"
                      aria-label="enabled"
                      checked={hiddenFields.includes(BrandContactFields.Website)}
                      onChange={() => onChangeHiddenFields(BrandContactFields.Website)}
                    />
                  </FormLabel>
                </div>
              </div>
              <div className="py-5 border-b border-gray-200">
                <h4 className="text-sm font-medium mb-4">Socials</h4>
                <div className="grid grid-cols-2 gap-4">
                  <FormField>
                    <FormLabel>Facebook URL</FormLabel>
                    <Input
                      id="facebook"
                      name="facebook"
                      defaultValue={brand.facebook ?? ''}
                      variant="standard"
                      ref={register}
                      width="full"
                    />
                  </FormField>
                  <FormLabel htmlFor="isEmailHidden">
                    Hide Facebook
                    <Checkbox
                      id="isFacebookHidden"
                      className="block"
                      aria-label="enabled"
                      checked={hiddenFields.includes(BrandContactFields.Facebook)}
                      onChange={() => onChangeHiddenFields(BrandContactFields.Facebook)}
                    />
                  </FormLabel>
                  <FormField>
                    <FormLabel>Instagram URL</FormLabel>
                    <Input
                      id="instagram"
                      name="instagram"
                      defaultValue={brand.instagram ?? ''}
                      variant="standard"
                      ref={register}
                      width="full"
                    />
                  </FormField>
                  <FormLabel htmlFor="isEmailHidden">
                    Hide Instagram
                    <Checkbox
                      id="isInstagramHidden"
                      className="block"
                      aria-label="enabled"
                      checked={hiddenFields.includes(BrandContactFields.Instagram)}
                      onChange={() => onChangeHiddenFields(BrandContactFields.Instagram)}
                    />
                  </FormLabel>
                </div>
              </div>
              <div className="py-5 border-b border-gray-200">
                <h4 className="text-sm font-medium mb-4">Invoice Details</h4>
                <div className="grid grid-cols-1 gap-4">
                  <FormField>
                    <FormLabel>VAT Number</FormLabel>
                    <Input
                      id="vatNumber"
                      name="vatNumber"
                      defaultValue={brand.vatNumber ?? ''}
                      variant="standard"
                      ref={register}
                      width="full"
                    />
                  </FormField>
                  <FormField className="space-y-4">
                    <strong>Invoice Address</strong>
                    <FormLabel>
                      Address Line 1
                      <Input
                        id="invoiceAddress.address1"
                        name="invoiceAddress.address1"
                        defaultValue={brand.invoiceAddress?.address1 ?? ''}
                        variant="standard"
                        ref={register}
                        width="full"
                      />
                    </FormLabel>
                    <FormLabel>
                      Address Line 2
                      <Input
                        id="invoiceAddress.address2"
                        name="invoiceAddress.address2"
                        defaultValue={brand.invoiceAddress?.address2 ?? ''}
                        variant="standard"
                        ref={register}
                        width="full"
                      />
                    </FormLabel>
                    <FormLabel>
                      City
                      <Input
                        id="invoiceAddress.city"
                        name="invoiceAddress.city"
                        defaultValue={brand.invoiceAddress?.city ?? ''}
                        variant="standard"
                        ref={register}
                        width="full"
                      />
                    </FormLabel>
                    <FormLabel>
                      Postal Code
                      <Input
                        id="invoiceAddress.zip"
                        name="invoiceAddress.zip"
                        defaultValue={brand.invoiceAddress?.zip ?? ''}
                        variant="standard"
                        ref={register}
                        width="full"
                      />
                    </FormLabel>
                    <FormLabel>
                      Province
                      <Input
                        id="invoiceAddress.province"
                        name="invoiceAddress.province"
                        defaultValue={brand.invoiceAddress?.province ?? ''}
                        variant="standard"
                        ref={register}
                        width="full"
                      />
                    </FormLabel>
                  </FormField>
                </div>
              </div>
              <div className="py-5 border-b border-gray-200">
                <h4 className="text-sm font-medium mb-4">Products</h4>
                <div className="grid grid-cols-2 gap-4">
                  <Controller
                    defaultValue={brand.meta?.includeRecentlySold}
                    control={control}
                    name="meta.includeRecentlySold"
                    render={({ onChange, value }) => (
                      <FormLabel htmlFor="meta.includeRecentlySold">
                        <span className="mr-4">Include Recently Sold Products?</span>
                        <Checkbox
                          id="meta.includeRecentlySold"
                          aria-label="enabled"
                          checked={value}
                          onChange={() => onChange(!value)}
                        />
                      </FormLabel>
                    )}
                  />
                  <Controller
                    defaultValue={brand.productSort}
                    control={control}
                    name="productSort"
                    render={({ onChange, value }) => (
                      <FormLabel>
                        <span className="mr-4">Sort Order for Products</span>
                        {productSortOptions && productSortOptions.length > 0 ? (
                          <Select options={productSortOptions} value={value} onChange={onChange} />
                        ) : null}
                      </FormLabel>
                    )}
                  />
                  <Controller
                    defaultValue={brand.recommendedProductTags}
                    control={control}
                    name="recommendedProductTags"
                    render={({ onChange, value }) => (
                      <FormLabel>
                        <span className="mr-4">Recommended Product Tags</span>
                        {productTagSelectItems && productTagSelectItems.length > 0 ? (
                          <MultiSelectWithSearch items={productTagSelectItems} value={value} onChange={onChange} />
                        ) : null}
                      </FormLabel>
                    )}
                  />
                </div>
              </div>
              <Box className="flex w-2/3 gap-2 py-5 sticky bottom-2 bg-white">
                <Button variant="plain" type="button" onClick={cancel}>
                  Cancel
                </Button>
                <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
                  Save
                </Button>
              </Box>
            </Form>
          </div>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Provider Fields</h3>
            <p className="text-sm text-gray-600">Original information provided by the store</p>
          </div>
        </Card.Header>
        <Card.Content>
          <div className="grid grid-cols-3 gap-4 pb-4 border-b border-gray-200">
            <div>
              <InfoLabel>Name</InfoLabel>
              <InfoValue>{brand.providerName}</InfoValue>
            </div>
            <div>
              <InfoLabel>Description</InfoLabel>
              <InfoValue>{brand.providerDescription || '-'}</InfoValue>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-4 border-b border-gray-200">
            <div className="col-start-1">
              <InfoLabel>Phone Number</InfoLabel>
              <InfoValue>{brand.providerPhone}</InfoValue>
            </div>
            <div>
              <InfoLabel>Email</InfoLabel>
              <InfoValue>{brand.providerEmail}</InfoValue>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 py-4">
            <div className="col-start-1">
              <InfoLabel>Address</InfoLabel>
              <InfoValue>{brand.address}</InfoValue>
            </div>
          </div>
        </Card.Content>
      </Card>
    </Box>
  )
}
