import { Button, Form, FormError, FormLabel, Modal, Select } from '@plusplusminus/plusplusdash'
import { SingleDatePicker } from 'react-dates'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment'
import { gql } from '@apollo/client'
import { useUpdateOneVoucherMutation, VoucherStatus } from 'generated'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

interface Voucher {
  id: string
  status: VoucherStatus
  expiryDate: Date
}

type EditVoucherModalProps = {
  isOpen: boolean
  onClose: () => void
  onUpdate: () => void
  voucher: Voucher | null
}

const EditVoucherModal = (props: EditVoucherModalProps) => {
  const { register, errors, control, handleSubmit, reset } = useForm({
    defaultValues: {
      id: '',
      status: VoucherStatus.Active,
      expiryDate: new Date()
    }
  })
  const [isDateFocused, setIsDateFocused] = useState(false)

  const [updateVoucherMutation, { loading: loadingUpdate }] = useUpdateOneVoucherMutation({
    onCompleted: (data) => {
      if (data.updateOneVoucher) {
        toast.success('Voucher updated successfully')
        props.onUpdate()
      }
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error updating voucher')
    }
  })

  useEffect(() => {
    if (props.voucher) {
      reset({
        id: props.voucher.id,
        expiryDate: new Date(props.voucher.expiryDate),
        status: props.voucher.status as VoucherStatus
      })
    }
  }, [props.voucher, reset])

  const onSubmit = (data: { status: VoucherStatus; expiryDate: Date }) => {
    updateVoucherMutation({
      variables: {
        input: {
          id: props.voucher?.id ?? '',
          update: {
            status: data.status,
            expiryDate: data.expiryDate
          }
        }
      }
    })
  }

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="md">
      <Modal.Title>Edit Voucher</Modal.Title>
      <Modal.Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-1 gap-y-4 py-4">
            <input type="hidden" name="id" ref={register} />
            <FormLabel>
              Status
              <Controller
                name="status"
                control={control}
                rules={{ required: 'Status is required' }}
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    items={Object.values(VoucherStatus).map((status) => ({
                      key: status,
                      label: status,
                      value: status
                    }))}
                  />
                )}
              />
              {errors.status ? <FormError>{errors.status.message}</FormError> : null}
            </FormLabel>
            <FormLabel>
              Expiry Date
              <Controller
                name="expiryDate"
                control={control}
                rules={{ required: 'Expiry date is required' }}
                render={({ onChange, value }) => (
                  <SingleDatePicker
                    id="expiry_date"
                    block
                    regular
                    date={moment(value)}
                    onDateChange={(date) => onChange(date?.toDate())}
                    focused={isDateFocused}
                    onFocusChange={({ focused }) => setIsDateFocused(focused)}
                  />
                )}
              />
              {errors.expiryDate ? <FormError>{errors.expiryDate.message}</FormError> : null}
            </FormLabel>
            <div className="justify-self-center w-56">
              <Button
                variant="primary"
                colorScheme="green"
                className="w-full justify-center"
                isDisabled={loadingUpdate}
              >
                {loadingUpdate ? 'Updating...' : 'Update'}
              </Button>
            </div>
          </div>
        </Form>
      </Modal.Description>
    </Modal>
  )
}

// Add updateVoucher mutation
EditVoucherModal.mutation = {
  updateVoucher: gql`
    mutation UpdateOneVoucher($input: UpdateOneVoucherInput!) {
      updateOneVoucher(input: $input) {
        id
        status
        expiryDate
      }
    }
  `
}

export default EditVoucherModal
