import { Box, Checkbox, Form, FormField, FormLabel, Input, MultiSelectWithSearch } from '@plusplusminus/plusplusdash'
import {
  useSetImageOnProductMutation,
  useSetImagesOnProductMutation,
  useUpdateProductVariantMutation
} from 'hooks/products'
import { useAllTags } from 'hooks/tags'
import { Controller, useForm } from 'react-hook-form'
import { buildTagSelectOptions } from 'utils/tags'
import cn from 'classnames'
import { ChangeEvent, useMemo, useRef, useState } from 'react'
import { isEqual, isEmpty } from 'lodash'
import { Upload } from 'components/Upload'
import { getDirtyFieldValues } from 'utils'
import { Card } from 'components/Card'
import { InfoLabel } from 'components/Info/InfoLabel'
import { InfoValue } from 'components/Info/InfoValue'
import Button from 'components/Button'
import { StarIcon, ClipboardCopyIcon } from '@heroicons/react/solid'
import { TagTypes } from 'common/enums'
import { Badge } from 'components/Badge'
import { Image, ProductQueryQuery, ProductsInput, UpdateProductMutation, useUpdateProductMutation } from 'generated'
import toast from 'react-hot-toast'
import { useAuthToken } from 'hooks/useAuthToken'

interface ProductEditProps {
  productId: string
  product: NonNullable<ProductQueryQuery['product']>
  cancel: () => void
  updateCallback: () => void
}

const apiUrl = process.env.REACT_APP_TLE_API_URL || ''

const ProductEdit: React.FC<ProductEditProps> = (props) => {
  const { productId, product, cancel, updateCallback } = props
  const [authToken] = useAuthToken()
  const [uploading, setUploading] = useState(false)

  /* Local State */
  const [featuredImage, setFeaturedImage] = useState(product.image)
  const [productTags, setTags] = useState(product.tags.map(({ id }) => id))
  const areTagsChanged = useRef(false)
  const [images, setImages] = useState(product.images)

  const originalVariants = useMemo(
    () =>
      product.variants.reduce((acc, variant) => {
        acc[variant.id] = variant.title
        return acc
      }, {} as { [x: string]: string }),
    [product]
  )

  const [variants, setVariants] = useState(originalVariants)

  /* Form */
  const {
    register,
    control,
    handleSubmit,
    formState: { dirtyFields },
    setValue
  } = useForm<UpdateProductMutation['updateOneProduct']>({
    defaultValues: {
      title: product.title,
      description: product.description,
      enabled: product.enabled,
      image: product.image
    }
  })

  /* Mutations */
  const [updateProduct, { loading: loadingUpdateProduct }] = useUpdateProductMutation({
    onCompleted: () => {
      toast.success('Successfully saved product')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not save product')
    }
  })
  const { setImageOnProduct, loading: loadingSetImage } = useSetImageOnProductMutation({
    onCompleted: () => {
      toast.success('Successfully set image on product')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not set image on product')
    }
  })
  const { setImagesOnProduct, loading: loadingSetImages } = useSetImagesOnProductMutation({
    onCompleted: () => {
      toast.success('Successfully set images on product')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not set images on product')
    }
  })

  const { updateProductVariant, loading: loadingProductVariant } = useUpdateProductVariantMutation({
    onCompleted: () => {
      toast.success('Successfully updated product variant')
      updateCallback()
    },
    onError: (error) => {
      console.log({ error })
      toast.error('Error. could not update product variant')
    }
  })

  /* Queries */
  const [tags] = useAllTags(TagTypes.Product)

  const onSubmit = async (data: ProductsInput) => {
    const promises: Array<Promise<any> | void> = []

    if (!isEqual(originalVariants, variants)) {
      Object.keys(variants).forEach((id) => {
        if (originalVariants[id] !== variants[id]) {
          promises.push(updateProductVariant(id, { title: variants[id] }))
        }
      })
    }

    if (!isEqual(images, product.images)) {
      promises.push(
        setImagesOnProduct(
          product.id,
          images.map((f) => f.id)
        )
      )
    }

    const updates = getDirtyFieldValues(data, dirtyFields)
    if (areTagsChanged.current) {
      updates.tagIds = productTags
    }
    if (featuredImage && featuredImage?.url !== product.image?.url) {
      updates.imageId = featuredImage.id
      promises.push(setImageOnProduct(product.id, featuredImage.id))
    }
    if (!isEmpty(updates)) {
      const keys = Object.keys(updates) as Array<keyof typeof updates>
      keys.forEach((k) => {
        if (updates[k] === '') {
          updates[k] = null
        }
      })
      promises.push(updateProduct({ variables: { id: productId, update: updates } }))
    }

    await Promise.all(promises)
  }

  const onChangeTags = (value: string[]) => {
    setTags(value)
    areTagsChanged.current = true
  }

  const onRemoveImage = (url: string) => () => {
    const filterImgs = images.filter((f) => f.url !== url)
    setImages(filterImgs)

    // set featured image to first if removed
    if (featuredImage && featuredImage.url === url) {
      setFeaturedImage(filterImgs[0])
    }
  }

  const onAddImage = (image: Image) => () => {
    setImages(images.concat(image))
  }
  const onUpload = async (event: any) => {
    const files = event?.target?.files
    setUploading(true)

    try {
      for (const file of files) {
        const formData = new FormData()
        formData.append('file', file)

        const response = await fetch(`${apiUrl}/images/upload`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        })

        if (!response.ok) {
          throw new Error('Upload failed')
        }

        const image = await response.json()

        if (image && images && !images.find((f) => f.id === image.id)) {
          setImages((state) => [...(state || []), image])
        }
      }
    } catch (error) {
      console.error(error)
      toast.error('Server error. could not upload image.')
    } finally {
      setUploading(false)
    }
  }

  const updateVariant = (id: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setVariants({ ...variants, [id]: e.target.value })
  }

  const tagSelectItems = buildTagSelectOptions(tags)

  const loading = loadingUpdateProduct || loadingProductVariant || uploading || loadingSetImage || loadingSetImages
  return (
    <Box className="p-5">
      <Card className="mb-5">
        <Card.Content>
          <div className="flex gap-8">
            <Form onSubmit={handleSubmit(onSubmit)} className="w-1/2">
              <FormField className="mb-4">
                <FormLabel htmlFor="title">Title</FormLabel>
                <Input
                  variant="standard"
                  width="full"
                  id="title"
                  name="title"
                  ref={register}
                  defaultValue={product.title ?? undefined}
                  placeholder={product.providerTitle}
                />
              </FormField>
              <FormField className="mb-4">
                <div className="flex justify-between items-center mb-1">
                  <FormLabel htmlFor="description">Description</FormLabel>
                  <Button
                    variant="plain"
                    size="sm"
                    onClick={() => setValue('description', product.providerDescription)}
                    type="button"
                  >
                    <ClipboardCopyIcon className="w-4 h-4" />
                    Copy Original Description
                  </Button>
                </div>
                <Input
                  as="textarea"
                  variant="standard"
                  width="full"
                  id="description"
                  name="description"
                  ref={register}
                  defaultValue={product.description ?? undefined}
                  placeholder={product.providerDescription ?? undefined}
                  className="h-64"
                />
              </FormField>
              <div className="flex">
                <div className="mb-4 w-32">
                  <FormField direction="row">
                    <FormLabel className="mr-2">Enabled</FormLabel>
                    <Controller
                      name="enabled"
                      defaultValue={product.enabled}
                      control={control}
                      render={({ value, onChange }) => (
                        <Checkbox id="enabled" checked={value} onClick={() => onChange(!value)} />
                      )}
                    />
                  </FormField>
                </div>
              </div>
              <div>
                <span className="text-sm text-gray-500">Original Images</span>
                <div className="flex gap-3 flex-wrap">
                  {product.providerImages.map((img) => (
                    <div className="flex relative">
                      <img src={img.url} className="w-24 object-contain p-1 border-2 border-transparent" />
                      {images.find((f) => f.url === img.url) ? null : (
                        <button
                          type="button"
                          className="absolute top-0 right-0 bg-green-500 text-white rounded-full shadow"
                          onClick={onAddImage(img)}
                        >
                          <span className="sr-only">Dismiss</span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <span className="text-sm text-gray-500">Select Featured Image and Images</span>
                <div className="flex gap-3 flex-wrap">
                  {images.map((img) => {
                    const isFeatured = img.url === featuredImage?.url
                    return (
                      <div
                        className={cn('flex relative border-2', {
                          'border-blue-600': isFeatured,
                          'border-transparent': !isFeatured
                        })}
                      >
                        <img
                          src={img.url}
                          className={cn('w-24 object-contain p-1 cursor-pointer')}
                          onClick={() => setFeaturedImage(img)}
                        />
                        {images.length > 1 ? (
                          <button
                            type="button"
                            className="absolute top-0 right-0 bg-red-400 text-white rounded-full shadow"
                            onClick={onRemoveImage(img.url)}
                          >
                            <span className="sr-only">Dismiss</span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                strokeWidth="2"
                                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                              />
                            </svg>
                          </button>
                        ) : null}
                      </div>
                    )
                  })}
                  <Upload uploading={uploading} onUpload={onUpload} />
                </div>
              </div>
              <FormLabel htmlFor="isImageContained">
                <span className="mr-2">Contain Image?</span>
                <Controller
                  name="isImageContained"
                  defaultValue={product.isImageContained}
                  control={control}
                  render={({ value, onChange }) => (
                    <Checkbox id="isImageContained" checked={value} onClick={() => onChange(!value)} />
                  )}
                />
              </FormLabel>
              <Box className="flex w-2/3 mt-8 gap-2">
                <Button variant="plain" type="button" onClick={cancel}>
                  Cancel
                </Button>
                <Button variant="primary" colorScheme="green" type="submit" isDisabled={loading} isLoading={loading}>
                  Save
                </Button>
              </Box>
            </Form>
            <Form className="w-1/2">
              <FormField>
                <FormLabel className="text-gray-700">Tags</FormLabel>
                {tagSelectItems.length > 0 ? (
                  <MultiSelectWithSearch
                    items={tagSelectItems}
                    value={product.tags.map((tag) => tag.id)}
                    onChange={onChangeTags}
                  />
                ) : null}
              </FormField>
              <p className="text-sm font-medium text-gray-500 border-b border-gray-300 py-2">Variants</p>
              {product.variants?.map((variant, index) => {
                return (
                  <div
                    className={cn('p-2 border-b border-gray-300', {
                      'bg-gray-50': index % 2 === 0
                    })}
                  >
                    <Input
                      variant="standard"
                      className="text-md mb-2"
                      defaultValue={variants[variant.id]}
                      onChange={updateVariant(variant.id)}
                    />
                    <div className="grid grid-cols-3 gap-y-3 gap-x-4 w-full">
                      {variant.selectedOptions.map((opt) => (
                        <div className="flex gap-1 col-start-1">
                          <span className="text-sm text-gray-500">{opt.name}: </span>
                          <span className="text-sm text-black">{opt.value}</span>
                        </div>
                      ))}
                      <div className="flex gap-1 col-start-2 row-start-1">
                        <span className="text-sm text-gray-500">SKU: </span>
                        <span className="text-sm text-black">{variant.sku}</span>
                      </div>
                      <span className="text-sm text-black col-start-3 row-start-1">
                        <span className="text-sm text-gray-500">Price: </span>
                        <span className="text-sm text-black">R{variant.price}</span>
                      </span>
                      {/* <span className="text-sm underline text-right col-start-3">View</span> */}
                    </div>
                  </div>
                )
              })}
            </Form>
          </div>
        </Card.Content>
      </Card>
      <Card>
        <Card.Header>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Provider Fields</h3>
            <p className="text-sm text-gray-600">Original information provided by the store</p>
          </div>
        </Card.Header>
        <Card.Content>
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div>
              <InfoLabel>Title</InfoLabel>
              <InfoValue>{product.providerTitle}</InfoValue>
            </div>
            <div className="col-span-2">
              <InfoLabel>Description</InfoLabel>
              <InfoValue>{product.providerDescription}</InfoValue>
            </div>
            <div className="col-start-1">
              <InfoLabel>Tags</InfoLabel>
              {product.providerTags?.map((tag) => (
                <Badge className="mr-1" color="gray">
                  {tag}
                </Badge>
              ))}
            </div>
          </div>
          <div>
            <InfoLabel className="mb-2">Images</InfoLabel>
            <div className="flex gap-2 flex-wrap">
              {product.providerImages.map((img) => (
                <div className="w-32 p-2 relative">
                  <img src={img.url} className="w-full object-contain" />
                  {product.providerImage === img.url ? (
                    <StarIcon className="absolute top-0 right-0 text-yellow-400 w-5 h-5" />
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </Card.Content>
      </Card>
    </Box>
  )
}

export { ProductEdit }
